<template>
  <h2>Нет данных в секции.</h2>
  <ul v-if="props.data">
    <li>Редактор: {{ props.data.tag }}</li>
    <li>UUID редактора: {{ props.data.uuid }}</li>
    <li>Представление редактора: {{ props.data.attributes['component-view'] }}</li>
  </ul>
</template>

<script setup lang="ts">
// Types
import type { IUtilsDataMissingProps } from '~/components/Utils/Data/Missing/UtilsDataMissing.types';

const props = defineProps<IUtilsDataMissingProps>();
</script>
