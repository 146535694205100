import Ajv from 'ajv';
import type { JSONSchemaType } from 'ajv';
import type { IComponentProps } from '~/typings/types/component.types';
import { EErrorType, errorHandler } from '~/utils/errorHandlers/customErrorHandler';

const ajv = new Ajv({ allowUnionTypes: true });

/**
 * @description Composable for validate component schema
 * @param {JSONSchemaType<OrganismDataType>} schema
 * @param {IComponentProps} props
 */
export default function useComponentSchemaValidator<OrganismDataType>(
  schema: JSONSchemaType<OrganismDataType>,
  props: IComponentProps<OrganismDataType>,
): void {
  if (import.meta.client) return;
  if (!props) return;
  if (!props.data) return;
  if (!props.data.component) return;

  const validate = ajv.compile(schema);

  if (validate(props.data.component)) return;

  return errorHandler.throwError(
    EErrorType.NUXT_SERVER_ERROR,
    `Ошибка в структуре данных для редактора ${props.viewName}.`,
    `
      Ошибка валидации: ${JSON.stringify(validate.errors, null, 2)}
      \n
      \n
      Передано: ${JSON.stringify(props.data, null, 2)}
    `,
  );
}
